<template>
    <div class="people-card" :style="containerStyle">
        <div class="people-header">
            <div class="row flex-nowrap text-truncate no-gutters w-100">
                <div class="col text-truncate mr-1">
                    <user-picture-name-nickname :user="user" />
                </div>
                <div class="col-auto">
                    <follow-entity-button
                        v-if="!user.is_blocked"
                        :entity-id="user.id"
                        :entity-name="user.displayname"
                        :is-following="Number(user.is_following)"
                        entity="users"
                        @toggled-follow="(is_following) => $emit('toggled-follow', is_following)"
                    />
                    <button v-else class="unblock-user" @click="$emit('unblock-user', user.id)">
                        Blocked
                    </button>
                </div>
            </div>
        </div>
        <p v-if="showDescription" class="people-description">
            {{ user.description }}
        </p>
        <!-- <router-link :to="{ name: 'web-app-profile', params: { displayName: user.displayname } }">
            <p class="people-description">
                {{ user.description }}
            </p>
        </router-link> -->
    </div>
</template>

<script>
export default {
    namme: "CreatorsCard",
    components: {
        UserPictureNameNickname: () => import(/* webpackChunkName: "user-picture-name-nickname" */ "@c/molecules/user-picture-name-nickname"),
        FollowEntityButton: () => import(/* webpackChunkName: "follow-entity-button" */ "@c/atoms/follow-entity-button")
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        showDescription: {
            type: Boolean,
            default: false
        },
        containerStyle: {
            type: Object,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.people-card {
    width: 276px;
    background-color: #1C1C1E;
    padding: 12px;
    margin-bottom: 24px;
    border-radius: 8px;

    a {
        color: inherit;
        text-decoration: none;
    }

    @media(max-width: $sm) {
        max-width: 300px;
    }

    .user-picture-name-followers {
        /deep/ .row {
            flex-wrap: nowrap;

            .col.col-sm-auto {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }
        }
    }

    .people-header {
        display: flex;
        justify-content: space-between;

        /deep/ .user-picture {
            .verified-user {
                width: 20px;
            }

            figure {
                width: 40px;
                height: 40px;
            }
        }

        /deep/ .user-name {
            span {
                font-size: 16px;
            }
        }

        /deep/ .follow-button {
            .btn {
                width: 68px;
            }
        }
    }

    .people-description {
        @extend .body-text;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .unblock-user {
        padding: 10px 20px;
        border: 1px solid $error-color;
        background-color: white;
        border-radius: 20px;
        font-size: 14px;
    }
}
</style>
